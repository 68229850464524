// ES6 Polyfills
import 'core-js/modules/es.array.for-each'
import 'core-js/modules/es.array.map'
import 'core-js/modules/es.object.keys'
import 'core-js/modules/es.promise'

// Essentials
import Emitter from 'tiny-emitter/instance'
import WhenDomReady from 'when-dom-ready'
import LoadJS from 'loadjs'
import { throttle as FrameThrottle } from 'frame-throttle'
import IsMobile from 'ismobilejs'
import { detect as DetectBrowser } from 'detect-browser'

// Modules
import { ResponsiveImages } from 'modules/responsive-images'
import { LazySizes } from 'modules/lazy-sizes'
import { Polyfills } from 'modules/polyfills'
import { MobileDetect } from 'modules/mobile-detect'

export const Kernel = {
  setup: () => {
    if (typeof window.payload !== 'object') {
      throw new Error('Panic: No payload found.')
    }

    Kernel.debug = window.payload.kernel.debug
    Kernel.config = window.payload.kernel.config
    Kernel.browser = DetectBrowser()
    Kernel.mobileDevice = IsMobile(navigator.userAgent)
    Kernel.isMobile = Kernel.mobileDevice.any
    Kernel.isPortrait = window.innerHeight > window.innerWidth
    Kernel.polyfills = Polyfills
    Kernel.cachedRemValue = Kernel.getRemValue()
    Kernel.domReady = false
    Kernel.isReady = true
    Kernel.passiveEvents = Polyfills.supportsPassive()
    Kernel.log('Setup', 'kernel')
  },

  boot: () => {
    Kernel.log('##############################', 'kernel')
    Kernel.log('########## Boot start', 'kernel')

    // Modules
    Kernel.loadModules()

    // Events
    Kernel.coreEvents()
    Kernel.emitEvent('kernel.boot')
    Kernel.subscribeOnce('dom.ready', () => { Kernel.domReady = true })
    WhenDomReady().then(() => Kernel.emitEvent('dom.ready'))

    // App
    Kernel.run()

    Kernel.log('########## Boot end', 'kernel')
    Kernel.log('##############################', 'kernel')
  },

  // Module management
  addModule: Module => {
    Kernel.log(`Add module '${Module.name}'`, 'kernel')
    Kernel.modules.push(new Module())
  },

  loadModules: () => {
    Kernel.modules.forEach(module => {
      Kernel.log(`Load module '${module.constructor.name}'`, 'kernel')
      module.run()
    })
  },

  // Load Apps dependencies
  run: () => {
    if (typeof Kernel.config.run === 'object') {
      Kernel.config.run.forEach(item => Kernel.loadDependency(item))
    } else {
      throw new Error('Nothing to run.')
    }
  },

  loadDependency: (dependency) => {
    Kernel.log(`Loading ${dependency.deps.length} dependencies for '${dependency.name}'`, 'kernel')

    LoadJS(dependency.deps, dependency.name)
    LoadJS.ready(dependency.name, {
      success: () => {
        Kernel.log(`Loaded dependencies for ${dependency.name}`, 'kernel')
        Kernel.emitEvent('dependency.loaded', { name: dependency.name })
      },

      error: (err) => {
        throw new Error(`Failed to load dependencies for ${dependency.name} (${err}`)
      }
    })
  },

  // Event management
  subscribeTo: (eventName, callback, context = null) => {
    Kernel.log(`Subscribe to '${eventName}'`, 'kernel')
    Emitter.on(eventName, callback, context)
  },

  subscribeOnce: (eventName, callback, context = null) => {
    Kernel.log(`Subscribe once to '${eventName}'`, 'kernel')
    Emitter.once(eventName, callback, context)
  },

  unsubscribeTo: (eventName, callback) => {
    Kernel.log(`Unsubscribe To '${eventName}'`, 'kernel')
    Emitter.off(eventName, callback)
  },

  emitEvent: (eventName, event = {}) => {
    Kernel.log(`Emit '${eventName}'`, 'kernel')
    Emitter.emit(eventName, event)
  },

  coreEvents: () => {
    // Window resize event
    const resizeEvent = FrameThrottle((e) => Kernel.emitEvent('window.resize', e))
    window.addEventListener('resize', resizeEvent)

    // rem change event
    const remChangeEvent = () => {
      const currentRemValue = Kernel.getRemValue()

      if (Kernel.cachedRemValue !== currentRemValue) {
        Kernel.emitEvent('kernel.remchange', { old: Kernel.cachedRemValue, new: currentRemValue })
        Kernel.cachedRemValue = currentRemValue
      }
    }

    // Set vh css var
    const vhUpdate = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
      document.documentElement.style.setProperty('--vh100', `${window.innerHeight}px`)
    }

    // Device orientation change
    const deviceOrientationChange = () => {
      const isPortrait = window.innerHeight > window.innerWidth

      if (Kernel.isPortrait !== isPortrait) {
        Kernel.emitEvent('kernel.orientationChange', { isPortrait: isPortrait })
        Kernel.isPortrait = isPortrait
      }
    }

    Kernel.subscribeTo('window.resize', vhUpdate)
    Kernel.subscribeTo('window.resize', remChangeEvent)
    Kernel.subscribeTo('window.resize', deviceOrientationChange)

    if (Kernel.isMobile) {
      vhUpdate()
      Kernel.subscribeTo('kernel.orientationChange', vhUpdate)
    }

    const scrollEvent = FrameThrottle((e) => Kernel.emitEvent('window.scroll', e))
    window.addEventListener('scroll', scrollEvent, Kernel.passiveEvents ? { passive: true } : false)

    window.onpopstate = e => Kernel.emitEvent('history.popstate', e)
  },

  // Dom readiness
  isDomReady: () => {
    return Kernel.domReady
  },

  // Logging and debug
  log: (message, from = null) => {
    if (Kernel.debug) {
      console.log((from ? `[${from.toUpperCase()}] ` : '') + message)
    }
  },

  // Utils
  getRemValue: () => {
    return parseFloat(getComputedStyle(document.documentElement).fontSize)
  }
}

if (Kernel.isReady !== true) {
  // Kernel setup
  Kernel.debug = null
  Kernel.config = []
  Kernel.modules = []

  // Kernel init
  Kernel.setup()

  Kernel.addModule(ResponsiveImages)
  Kernel.addModule(LazySizes)
  Kernel.addModule(Polyfills)
  Kernel.addModule(MobileDetect)

  Kernel.boot()

  window.Kernel = Kernel
}

export class LazySizes {
  constructor () {
    window.lazySizesConfig = window.lazySizesConfig || {}
    window.lazySizesConfig.lazyClass = 'image--lazyload'
    window.lazySizesConfig.loadedClass = 'image--lazyloaded'
    window.lazySizesConfig.loadingClass = 'image--lazyloading'
    window.lazySizesConfig.preloadAfterLoad = true
  }

  run () {
    require('lazysizes/lazysizes')
    require('lazysizes/plugins/object-fit/ls.object-fit')
    require('lazysizes/plugins/parent-fit/ls.parent-fit')
  }
}
